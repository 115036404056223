const cfGroupListing = function ($) {
    var api = {};

    function activeGroupMemberToken(element) {
        var activedivs = element.querySelectorAll('.group-member');
        var currentUrl = window.location.href.toLowerCase().trim();

        if (currentUrl.indexOf('#') > 0) {
            var trimpart = currentUrl.indexOf('#');
            currentUrl = currentUrl.substring(0, trimpart);
        }

        currentUrl = encodeURIComponent(currentUrl);
        const len = activedivs.length;

        for (var i = 0; i < len; i++) {
            var activedivurl = encodeURIComponent(
                activedivs[i].querySelector('a').href.toLowerCase().trim()
            );
            if (activedivurl.includes(currentUrl)) {
                activedivs[i].classList.add('group-member--active');
            }
        }

        return false;
    }

    /**
     * initInstance method prepare Dom elements
     */
    api.initInstance = function (component) {
        // Activate functionality for highlighting the active group member
        // based on style selection of Group Listing
        if (component.classList.contains('group-listing--active-image')) {
            activeGroupMemberToken(component);
        }
    };

    /**
     * init method calls in a loop for each component on page
     */
    api.init = function () {
        var groupListings = document.querySelectorAll(
            '.group-listing:not(.initialized)'
        );

        groupListings.forEach(function (groupListing) {
            api.initInstance(groupListing);
            groupListing.classList.add('initialized');
        });
    };

    return api;
};
export default cfGroupListing;
