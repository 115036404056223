import { css } from 'lit';

export const buttonStyles = css`
    :host([data-theme='dark']) .btn {
        --cf-btn-border-color: var(--cf-color-white);
        --cf-btn-hover-border-color: var(--cf-color-white);
    }

    :host([data-theme='dark']) .btn-secondary {
        --cf-btn-color: var(--cf-color-white);
        --cf-btn-hover-color: var(--cf-color-black);
        --cf-btn-hover-bg: var(--cf-color-white);
        --cf-btn-bg: var(--cf-color-black);
    }

    :host([data-theme='dark']) .btn-primary {
        --cf-btn-hover-bg: var(--cf-color-white);
        --cf-btn-hover-color: var(--cf-color-black);
    }

    .btn {
        --cf-btn-padding-x: var(--cf-space-xxs);
        --cf-btn-padding-y: var(--cf-space-xxxxs);
        --cf-btn-font-size: var(--cf-font-size-md);
        --cf-btn-font-weight: 400;
        --cf-btn-line-height: var(--cf-font-size-xl);
        --cf-btn-bg: transparent;
        --cf-btn-color: var(--cf-color-black);
        --cf-btn-border-width: var(--cf-border-width);
        --cf-btn-border-color: transparent;
        --cf-btn-border-radius: var(--cf-border-radius);
        --cf-btn-hover-border-color: transparent;
        --cf-btn-focus-shadow-rgb: 38, 121, 193;
        --cf-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
            0 1px 1px rgba(0, 0, 0, 0.075);
        --cf-btn-disabled-opacity: 0.65;
        --cf-btn-focus-box-shadow: 0 0 0 0.25rem
            rgba(var(--cf-btn-focus-shadow-rgb), 0.5);

        display: inline-block;
        padding: var(--cf-btn-padding-y) var(--cf-btn-padding-x);
        font-family: var(--cf-btn-font-family);
        font-size: var(--cf-btn-font-size);
        font-weight: var(--cf-btn-font-weight);
        line-height: var(--cf-btn-line-height);
        color: var(--cf-btn-color);
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
        border: var(--cf-btn-border-width) solid var(--cf-btn-border-color);
        border-radius: var(--cf-btn-border-radius);
        background-color: var(--cf-btn-bg);
    }

    .btn:hover {
        color: var(--cf-btn-hover-color);
        text-decoration: none;
        background-color: var(--cf-btn-hover-bg);
        border-color: var(--cf-btn-hover-border-color);
    }

    .btn:active {
        color: var(--cf-btn-active-color);
        background-color: var(--cf-btn-active-bg);
        border-color: var(--cf-btn-active-border-color);
    }

    .btn:focus-visible {
        color: var(--cf-btn-hover-color);
        background-color: var(--cf-btn-hover-bg);
        border-color: var(--cf-btn-hover-border-color);
        outline: 0;
        box-shadow: var(--cf-btn-focus-box-shadow);
    }

    .btn:disabled {
        background-color: grey;
    }

    .btn.btn-lg {
        --cf-btn-padding-y: 0.5rem;
        --cf-btn-padding-x: 1rem;
        --cf-btn-font-size: var(--cf-font-size-lg);
        --cf-btn-border-radius: var(--cf-border-radius-lg);
    }

    .btn-primary {
        --cf-btn-color: var(--cf-color-white);
        --cf-btn-bg: var(--cf-color-interactive);
        --cf-btn-border-color: var(--cf-color-interactive);
        --cf-btn-hover-color: var(--cf-color-white);
        --cf-btn-hover-bg: #00529b;
        --cf-btn-hover-border-color: #004e92;
        --cf-btn-focus-shadow-rgb: 38, 121, 193;
        --cf-btn-active-color: var(--cf-color-white);
        --cf-btn-active-bg: #004e92;
        --cf-btn-active-border-color: #004989;
        --cf-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        --cf-btn-disabled-color: var(--cf-color-white);
        --cf-btn-disabled-bg: var(--cf-color-interactive);
        --cf-btn-disabled-border-color: var(--cf-color-interactive);
    }

    .btn-secondary {
        --cf-btn-border-color: var(--cf-color-black);
        --cf-btn-border-color: #0f598a; 
        --cf-btn-hover-border-color: #0f598a; 
        --cf-btn-bg: var(--cf-color-white);
        --cf-btn-hover-bg: #004b90;
        --cf-btn-hover-color: var(--cf-color-white);
    }
`;
