export const DOM_SEARCH_RESULTS_LOADED = 'search-results-loaded';

document.body.addEventListener(
    DOM_SEARCH_RESULTS_LOADED,
    hidesearchResulttab);
function hidesearchResulttab(tabcontainer) {
    const totalresults = tabcontainer.detail.resultData.pagingInfo.total;
    if (totalresults == 0) {
        tabcontainer.srcElement
            .querySelectorAll('.cf-tabs')
            .forEach((el) => {
                el.querySelectorAll('.tabs-container .tab').forEach((content) => {
                    if (
                        content.querySelector('search-results')
                    ) {
                        const heading = el.querySelector(`#tab_${content.id}`);
                        heading?.remove();
                        content.remove();
                    }
                });
            });
    }

}
function select(el, show = true) {
    if (!el) return;

    el.classList.toggle('active', show);
    el.setAttribute('aria-selected', show ? 'true' : 'false');
    // eslint-disable-next-line no-param-reassign
    el.tabIndex = show ? 0 : -1;
}

function selectTab(item) {
    const contentId = item?.getAttribute('aria-controls');
    const content = document.getElementById(contentId);

    item?.parentNode
        .querySelectorAll('.active')
        .forEach((sibling) => select(sibling, false));
    content?.parentNode
        .querySelectorAll('.active')
        .forEach((sibling) => select(sibling, false));

    select(item);
    select(content);
}

function filterEmtpyTabs(container) {
    container.querySelectorAll('.tabs-container .tab').forEach((content) => {
        if (
            !content.querySelector('search-results') &&
            content.innerText.trim().length === 0 &&
            content.children.length <= 1
        ) {
            const heading = container.querySelector(`#tab_${content.id}`);
            heading?.remove();
            content.remove();
        }
    });
}

function tab(container) {
    filterEmtpyTabs(container);
    container
        .querySelector('.tabs-heading')
        .addEventListener('click', (evt) => {
            selectTab(evt.target.closest('li'));
        });

    const selected = container.querySelectorAll(
        '.tabs-heading [aria-selected=true],.tabs-heading [role=tab]'
    );

    if (selected.length > 0) {
        selectTab(selected[0]);
    }
}

function Tabs(selector = '.cf-tabs') {
    return {
        init() {
            document.querySelectorAll(`${selector}`).forEach((el) => tab(el));
        },
    };
}

export default Tabs;
