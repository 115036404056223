const INITIALIZED = 'initialized';

function CFBreadcrumb() {
    function cfbreadcrumbs(el) {
        el.classList.add(INITIALIZED);
        el.addEventListener('click', (event) => {
            event.preventDefault();
            event.stopPropagation();

            document
                .querySelector('#truncated-crumbs')
                .classList.add('truncated-crumbs_hide');

            document
                .querySelector('#expanded-crumbs')
                .classList.remove('expanded-crumbs_hide');
        });
    }

    return {
        init() {
            document
                .querySelectorAll(
                    `.cf-breadcrumb .crumb-ellipsis:not(.${INITIALIZED})`,
                )
                .forEach((el) => cfbreadcrumbs(el));
        },
    };
}

export default CFBreadcrumb;
