import jQuery from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import initPrivacy from '@cfed/privacy';
import initGroups from '@cfed/groups';
import initNavigation from '@cfed/navigation';
import initGeneralContent from '@cfed/general-content';
import initSearch from '@cfed/site-search';
import initIndicatorsAndData from '@cfed/indicators-and-data';
import initIcons from '@cfed/theming/assets/js/iconography';
import ClevelandFedSearchResults from './search';
import initCareers from './careers';

import '../scss/clevelandfed.scss';

globalThis.jQuery = jQuery;

const iconLoader = async () => (await import('./icons')).default;

initNavigation();
initPrivacy();
initGroups();
initIndicatorsAndData();
initGeneralContent('clevelandfed');
initSearch(ClevelandFedSearchResults);
initIcons(iconLoader);
initCareers();

if (globalThis?.SitecoreSettings) {
    import('@cfed/theming/assets/js/EnvironmentContextBanner');
}
