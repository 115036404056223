import { customImport } from '@cfed/theming/assets/js/helpers';

export default async function initNowcasting() {
    const charts = document.querySelectorAll('.nowcasting-chart');

    if (charts.length > 0) {
        // https://vitejs.dev/guide/env-and-mode.html#env-variables
        const base = (import.meta.env.BASE_URL).replace(/\/$/, '');
        await customImport(`${base}/bundles/fusioncharts.js`);
        const component = await import('./component-nowcasting-chart');
        const instance = component.default();

        charts.forEach((elem) => instance.init(elem));
    }
}
