import { LitElement, html, nothing } from 'lit';
import { property, state } from 'lit/decorators.js';
import { ref, createRef } from 'lit/directives/ref.js';
import { getHighstock, getZipcelx } from './highcharts/highcharts-service';

export const tagName = 'chart-highcharts';

customElements.whenDefined(tagName).then(() => {
    document.querySelectorAll('.cf-accordion').forEach((accordionEl) => {
        accordionEl.addEventListener('shown.bs.collapse', (evt) => {
            evt.srcElement
                .querySelectorAll(tagName)
                .forEach((el) => el?.reflow());
        });
    });
});

export class ChartHighcharts extends LitElement {
    chartRef = createRef();

    highcharts;

    zipcelx;

    constructor() {
        super();

        this._getDependencies();
    }

    @state()
    accessor dependencies;

    @property({ attribute: 'chart-id' })
    accessor chartId;

    @property({ attribute: false })
    accessor reflow = () => this._getChartInstance()?.reflow();

    createRenderRoot() {
        return this;
    }

    _getChartInstance(chartEl = this.chartRef?.value) {
        const chartIndex = parseInt(chartEl?.dataset?.highchartsChart);

        return this.highcharts?.charts?.[chartIndex];
    }

    async _getDependencies() {
        window.Highcharts = window.Highcharts ?? (await getHighstock());
        window.zipcelx = window.zipcelx ?? (await getZipcelx());

        this.highcharts = window.Highcharts;
        this.zipcelx = window.zipcelx;

        this.dependencies = true;
    }

    updated(changedProperties) {
        if (changedProperties.has('dependencies') && this.dependencies) {
            const configFn = window?.chartConfigs?.[this.chartId];

            if (configFn) configFn(this.chartRef.value);
        }
    }

    render() {
        if (!this.dependencies) return nothing;

        return html`<div ${ref(this.chartRef)}></div>`;
    }
}
