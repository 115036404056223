import { JSRenderMixin } from '@cfed/site-search/mixins';

export const CFEDTemplateService = JSRenderMixin(
    class {
        templateNames = [
            'resultList',
            'sortSelector',
            'pageSelector',
            'resultItem',
            'generalPageItem',
            'articleItem',
            'articleMeta',
            'speechMeta',
            'eventMeta',
            'taxonomy',
        ];

        formatDate(value) {
            return new Intl.DateTimeFormat('en-us', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                timeZone: 'America/New_York',
            })
                .format(Date.parse(value))
                .replaceAll('/', '.');
        }

        templateLoader = async (templateName) =>
            (await import(`./templates/${templateName}.tmpl?raw`)).default;
    }
);
