const handleAccordion = () => {
    document.querySelectorAll('.cf-accordion').forEach((accordionEl) => {
        accordionEl.addEventListener('shown.bs.collapse', (ev) => {
            ev.srcElement
                .querySelectorAll('div[data-highcharts-chart]')
                .forEach((el) => {
                    const { highchartsChart } = el.dataset;
                    const instance =
                        window?.Highcharts.charts[parseInt(highchartsChart)];

                    instance?.reflow();
                });
        });
    });
};

const chart = function () {
    return {
        init() {
            // Check if chartConfigs are present
            if (window.chartConfigs) {
                document.querySelectorAll('.chart').forEach((el) => {
                    el.classList.add('initialized');
                    const container = el.querySelector('div[id]');

                    if (container && window.chartConfigs) {
                        import('./chart-vendor').then(() => {
                            const configFn = window.chartConfigs[container.id];

                            configFn(container.id);
                        });
                    }
                });
                handleAccordion();
            }
        },
    };
};

export default chart;
