export async function getHighcharts() {
    return (await import('./highcharts')).default;
}

export async function getHighstock() {
    return (await import('./highstock')).default;
}

export async function getZipcelx() {
    return (await import('zipcelx')).default;
}
