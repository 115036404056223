import { LitElement, html, nothing } from 'lit';
import { property, state } from 'lit/decorators.js';
import Cookies from 'js-cookie';
import styles from './cookie-consent.styles';
import {
    buttonStyles,
    popoverStyles,
    heading6Styles,
} from '@cfed/theming/styles';

export const tagName = 'cf-cookie-consent';

export class CookieConsent extends LitElement {
    static styles = [heading6Styles, popoverStyles, buttonStyles, styles];

    cookieName = 'cookieconsent_status';

    @property({ attribute: 'consent-title' })
    accessor consentTitle = 'We care about privacy';

    @property({ attribute: 'label-allow' })
    accessor labelAllow = 'Accept cookies';

    @property({ attribute: 'label-deny' })
    accessor labelDeny = 'No thanks';

    @state()
    accessor cookieValue = Cookies.get(this.cookieName);

    _setCookie(value) {
        this.cookieValue = Cookies.set(this.cookieName, value, {
            expires: 365,
        });
    }

    render() {
        if (this.cookieValue) return nothing;

        return html`
            <div class="container" role="dialog" aria-modal="false">
                <div class="popover">
                    <h6 class="h6">${this.consentTitle}</h6>
                    <div class="message">
                        <slot></slot>
                    </div>
                    <div class="actions">
                        <button
                            class="btn btn-primary"
                            @click=${() => this._setCookie('allow')}
                        >
                            ${this.labelAllow}
                        </button>
                        <button
                            class="btn btn-secondary"
                            @click=${() => this._setCookie('deny')}
                        >
                            ${this.labelDeny}
                        </button>
                    </div>
                </div>
            </div>
        `;
    }
}

customElements.define(tagName, CookieConsent);
