import Backbone from 'backbone';
import _ from 'lodash';
import { debug } from './constants';

export const RESULTS_LOADING = 'results-loading';
export const DOM_SEARCH_RESULTS_LOADED = 'search-results-loaded';


export const events = {
    RESULTS_LOADING,
    DOM_SEARCH_RESULTS_LOADED,
};

function triggerDomEvent(el, eventName, payload) {
    if (debug) {
        console.log(`trigger dom ${eventName}`);
        console.log(el);
        console.log(payload);
    }
    try {
        const event = new CustomEvent(eventName, { detail: payload });
        // Remark, should we call out external dependency on document object?
        document.body.dispatchEvent(event);
    } catch (e) {
        console.log('error dispatching event');
        console.log(e);
    }
}

export default _.extend({ triggerDomEvent }, Backbone.Events);
