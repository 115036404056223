function cfStandalone(domain = 'clevelandfed') {
    return {
        init() {
            document
                .querySelectorAll(`a[href*='//']:not([href*=${domain}])`)
                .forEach(
                    (el) => el.querySelector('img') && el.classList.add('no-icon'),
                );
        },
    };
}
export default cfStandalone;
