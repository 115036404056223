import { library, dom } from '@fortawesome/fontawesome-svg-core';

export default async function initIcons(collection) {
    const icons = await collection();

    icons.forEach((icon) => library.add(icon));

    // Replace any existing <i> tags with <svg> and set up a MutationObserver to
    // continue doing this as the DOM changes.
    dom.watch();
}
