export function isInExperienceEditor() {
    return (
        !!(
            typeof Sitecore !== 'undefined'
            && typeof Sitecore.PageModes !== 'undefined'
            && typeof Sitecore.PageModes.PageEditor !== 'undefined'
        ) || document.body.classList.contains('on-page-editor')
    );
}

