import SearchFacets from './searchFacets.svelte';

export default class SearchFacetsCustomElement extends HTMLElement {
    #instance;

    async connectedCallback() {
        this.#instance = new SearchFacets({
            target: this,
            props: {
                facetHeading: this.getAttribute('facet-heading') ?? 'Details',
                facetReset: this.getAttribute('facet-reset') ?? 'Reset',
            },
        });
    }
}
