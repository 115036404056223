export function customImport(src, elements = [document.body]) {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.async = true;
        script.src = src;
        script.onload = resolve;
        script.onerror = reject;

        elements.map((el) => el.appendChild(script));
    });
}
