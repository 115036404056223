import { LitElement, html } from 'lit';
import { styleMap } from 'lit-html/directives/style-map.js';
import { property } from 'lit/decorators.js';
import { styles } from './graphic-list-image.styles';

export class GraphicList extends LitElement {
    static styles = styles;

    @property({ attribute: 'image-src' })
    accessor imageSrc = '';

    render() {
        const imgStyles = styleMap({
            'background-image': `url(${this.imageSrc})`,
        });

        return html`
            <div class="image">
                <div class="img-var" style=${imgStyles}></div>
                <div class="icon-bkg">
                    <slot name="icon"></slot>
                </div>
            </div>
            <div class="body">
                <slot name="content"></slot>
            </div>
        `;
    }
}
