const URL_TOKEN = '|~current-url~|';
const TITLE_TOKEN = '|~page-title~|';

export default class PageTools extends HTMLElement {
    connectedCallback() {
        const pageTitle = encodeURIComponent(document.title);
        const currentUrl = encodeURIComponent(window.location.href);

        this.querySelectorAll('.pt-item-a').forEach((el) => {
            // eslint-disable-next-line no-param-reassign
            el.href = el.href
                .replace(URL_TOKEN, currentUrl)
                .replace(TITLE_TOKEN, pageTitle);
        });
    }
}
