import { css } from 'lit';

export default css`
    .container {
        position: fixed;
        width: 30rem;
        left: 0;
        bottom: 0;
        z-index: 15;
    }

    .message {
        margin-top: var(--cf-space-xxxs);
        margin-bottom: var(--cf-space-xxs);
        font-size: var(--cf-font-size-sm);
    }

    .h6 {
        margin-bottom: 0;
        --heading-color: var(--cf-popover-color);
    }

    .actions {
        display: inline-grid;
        grid-auto-flow: column;
        gap: var(--cf-space-xxs);
    }

    @media screen and (max-width: 576px) {
        .container {
            right: 0;
            box-shadow: none;
            width: auto;
        }

        .actions {
            display: grid;
            grid-auto-flow: row;
            place-content: center;
            align-items: center;
        }
    }
`;
