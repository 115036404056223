import { LitElement, html } from 'lit';
import { property } from 'lit/decorators.js';
import { BrightCoveService } from './brightcove-video-service';

export const videoPlayerService = new BrightCoveService();

// This is a simple wrapper for the brightcove player for more information and
// documentation please follow the link below.
// https://player.support.brightcove.com/getting-started/index.html
export default class VideoPlayer extends LitElement {
    constructor() {
        super();

        videoPlayerService.videoPlayer(this.accountId, this.player, this.embed);
    }

    @property({ attribute: 'account-id' })
    accessor accountId = '6415806687001';

    @property({ attribute: 'video-id' })
    accessor videoId = '';

    @property({ attribute: 'playlist-id' })
    accessor playlistId = '';

    // Brightcove player w/ Video JS Schema
    // https://studio.brightcove.com/products/videocloud/players/LVjXpXhj6l
    @property()
    accessor player = 'LVjXpXhj6l';

    @property()
    accessor embed = 'default';

    @property()
    accessor controls = '';

    @property({ attribute: 'application-id' })
    accessor applicationId = '';

    createRenderRoot() {
        return this;
    }

    render() {
        return html`
            <video-js
                data-account-id=${this.accountId}
                data-embed=${this.embed}
                data-video-id=${this.videoId}
                data-playlist-id=${this.playlistId}
                data-player=${this.player}
                data-controls=${this.controls}
                data-application-id=${this.applicationId}
                width=""
                height=""
                class="vjs-16-9"
            ></video-js>
        `;
    }
}
