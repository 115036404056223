const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;

export function getCookies(cookies = document.cookie) {
    const items = {};
    decodeURIComponent(cookies)
        .split('; ')
        .map((item) => item.split('='))
        .forEach(([key, value]) => (items[key] = value));

    return items;
}

export function isCookieSet(name) {
    return getCookies().hasOwnProperty(name);
}

export function setExpirationInDays(days) {
    return new Date(Date.now() + days * ONE_DAY_IN_MS);
}

export function createCookie(name, value, days = 365, path = '/') {
    return [
        `${name}=${encodeURIComponent(value)}`,
        `expires=${setExpirationInDays(days).toUTCString()}`,
        `path=${path}`,
    ].join(';');
}

export function setCookie(cookie) {
    document.cookie = cookie;
}
