import { SearchResultsCustomElement } from '@cfed/site-search';

async function getTemplate(templateName) {
    const template = await import(`./templates/${templateName}.tmpl?raw`);

    return template.default;
}

export default class ClevelandFedSearchResults extends SearchResultsCustomElement {
    templateManager = getTemplate;
}
