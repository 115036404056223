import jQuery from "jquery";

export default function MegaMenu(selector = '.cf-mega-menu-desktop') {
    function handleDropDown(el) {
        return jQuery(el.querySelector('.dropdown-menu')).stop(true, true);
    }

    function menu(container) {
        container
            .querySelectorAll('.dropdown-menu-dt')
            .forEach((el) =>
                el.addEventListener('click', (event) => event.stopPropagation())
            );

        // Add slideDown animation to Bootstrap dropdown when expanding.
        container
            .querySelectorAll('.dropdown')
            .forEach((el) => {
                el.addEventListener('show.bs.dropdown', (event) => handleDropDown(event.currentTarget).slideDown());
                el.addEventListener('hide.bs.dropdown', (event) => handleDropDown(event.currentTarget).slideUp());
            });
    }


    document
        .querySelectorAll(selector)
        .forEach((el) => menu(el));
};

