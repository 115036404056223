import { customImport } from '@cfed/theming/assets/js/helpers';

export class BrightCoveService {
    cache = new Set();

    brightcoveUrl(accountId, playerId, embed) {
        return `https://players.brightcove.net/${accountId}/${playerId}_${embed}/index.min.js`;
    }

    videoPlayer(accountId, playerId, embed) {
        if (this.cache.has(accountId)) {
            return Promise.resolve();
        }
        this.cache.add(accountId);
        return customImport(this.brightcoveUrl(accountId, playerId, embed));
    }
}
