import { isCookieSet, setCookie, createCookie } from '../utils/cookies';

export class CookieConsent extends HTMLElement {
    static COOKIE_NAME = 'cookieconsent_status';

    handleDismiss() {
        this.style.visibility = 'hidden';
    }

    handlePrimaryChoice() {
        setCookie(createCookie(CookieConsent.COOKIE_NAME, 'allow'));
        this.handleDismiss();
    }

    handleSecondaryChoice() {
        setCookie(createCookie(CookieConsent.COOKIE_NAME, 'deny'));
        this.handleDismiss();
    }

    isExperience() {
        return this.hasAttribute('experience-editor');
    }

    isAcknowledge() {
        return isCookieSet(CookieConsent.COOKIE_NAME) && !this.isExperience();
    }

    connectedCallback() {
        const shadow = this.attachShadow({ mode: 'open' });
        if (!this.isAcknowledge()) {
            const el = document.createElement('template');

            el.innerHTML = this.getTemplate(this.isExperience());
            shadow.append(el.content);

            if (!this.isExperience()) {
                this.shadowRoot
                    .querySelector('.primary')
                    .addEventListener(
                        'click',
                        this.handlePrimaryChoice.bind(this)
                    );

                this.shadowRoot
                    .querySelector('.secondary')
                    .addEventListener(
                        'click',
                        this.handleSecondaryChoice.bind(this)
                    );
            }
        } else {
            this.handleDismiss();
        }
    }

    disconnectedCallback() {
        if (!this.isExperience()) {
            this.shadowRoot
                .querySelector('button')
                .removeEventListener('click', this.handlePrimaryChoice);
            this.shadowRoot
                .querySelector('.secondary')
                .removeEventListener('click', this.handleSecondaryChoice);
        }
    }

    getTemplate(isExperience) {
        const position = isExperience ? 'static' : 'fixed';

        return `
            <style> 
                :host {
                    --font-color: var(--cf-color-white);
                    --font-color-active: var(--cf-color-black);
                    --background-color: var(--cf-color-black);
                    --background-color-active: var(--cf-color-white);
                    --border-color: var(--cf-color-white);
                    --border-color-active: var(--cf-color-white);
                }
                .container {
                    position: ${position};
                    width: 30rem;
                    left: 0;
                    bottom: 0;
                    z-index: 15;
                }
                .title {
                    font-weight: 800;
                    font-size: 1rem;
                    margin: 0 0 0.5rem;
                    font-family: helveticanow-heavy, Helvetica, Arial,sans-serif;
                    letter-spacing: normal;
                    flex: 1;
                }
                .message {
                    display: block;
                    font-family: helveticanow-regular,Helvetica,Arial,sans-serif;
                    margin-top: var(--cf-space-xxxs);
                    margin-bottom: var(--cf-space-xxs);
                    font-size: var(--cf-font-size-sm);
                    line-height: 1.3125rem;
                }
                .popover {
                    position: relative;
                    background-color: var(--background-color);
                    color: var(--font-color);
                    border-top: var(--cf-border-default);
                    border-right: var(--cf-border-default);
                    border-bottom: var(--cf-border-default);
                    border-left: 0.625rem solid var(--cf-color-tertiary-6);
                    padding: var(--cf-space-xxs);
                    box-shadow: var(--cf-shadow-md);
                    border-radius: 0;
                    font-size: var(--cf-font-size-sm);
                    margin: var(--cf-space-xxs);
                    transition: var(--cf-transition);
                    overflow: hidden;
                }
                .h6 {
                  font-family: helveticanow-heavy,Helvetica,Arial,sans-serif;
                  font-size: 1rem;
                  letter-spacing: normal;
                  margin: 0;
                }
                .actions {
                  display: flex;
                  flex-wrap: wrap;

                }
                .button {
                    background-color: transparent;
                    color: var(--font-color);
                    background-color: var(--background-color);
                    border-color: var(--border-color);
                    font-size: 1rem;
                    line-height: 1.5rem;
                    border-radius: 0.25rem;
                    border-width: 0.0625rem;
                    border-style: solid;
                    cursor: pointer;
                    outline: none;
                    padding: 0.125rem 0.625rem;
                }
                .button:active,
                .button:focus,
                .button:focus-visible {
                    outline: none;
                    color: var(--font-color-active);
                    background-color: var(--background-color-active);
                    border-color: var(--border-color-active);
                }
                .button:hover {
                  outline: none;
                  color: var(--font-color-active);
                  background-color: var(--background-color-active);
                  border-color: var(--border-color-active);
                }
                .button + .button {
                  margin-left: var(--cf-space-xxs);
                }
                .primary {
                  background-color: var(--cf-color-brand-1);
                }                   
                .secondary:hover,
                .secondary:focus,
                .secondary:focus-visible {
                    outline: none;
                    color: var(--font-color-active);
                    border-color: var(--border-color-active);
                }
                @media screen and (max-width: 576px) {
                    .container {
                      right: 0;
                      box-shadow: none;
                      width: auto;
                    }
                    .button {
                      width: 100%;
                      max-width: 250px;
                    }
                    .button + .button {
                      margin-left: 0;
                      margin-top: var(--cf-space-xxs);
                    }
                    .actions {
                      flex-direction: column;
                      align-items: center;
                    }
                }
            </style>
            <div class="container" role="dialog" aria-modal="false">
                <div class="popover">
                    <h6 class="h6">
                      <slot name="consent-title"></slot>
                    </h6>
                    <div class="message">
                        <slot name="consent-message"></slot>
                    </div>
                    <div class="actions">
                        <button class="button primary">
                          <slot name="button-allow-label"></slot>
                        </button>
                        <button class="button secondary">
                          <slot name="button-deny-label"></slot>
                        </button>
                    </div>
                </div>
            </div>
        `;
    }
}
