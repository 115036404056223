import { css, unsafeCSS  } from 'lit';
import background from './graphic-list-image.mask.svg?url';

const imageBackground = `url("${background}")`;

export const styles = css`
    :host {
        --icon-background-color: #c09600;
        --icon-color: #fff;
        --icon-diameter: 100px;
        --image-background: ${unsafeCSS(imageBackground)};
        --image-width: 275px;
        --image-height: 215px;

        padding: 1rem;
        position: relative;
        display: grid;
        gap: 1.875rem;
    }

    .image {
        position: relative;
        width: var(--image-width);
        height: var(--image-height);
        background-image: var(--image-background);
        background-repeat: no-repeat;
        background-position: 30px 0;
    }

    .icon-bkg {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: var(--icon-background-color);
        height: var(--icon-diameter);
        width: var(--icon-diameter);
    }

    .icon-bkg ::slotted(*) {
        color: var(--icon-color);
        font-size: 3rem;
    }

    .img-var {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 60px;
        background: no-repeat;
        background-size: cover;
        border-radius: 50%;
        z-index: 1;
    }
`;
