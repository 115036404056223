import jQuery from "jquery";

export default function MegaMenuMobile(selector = '.cf-mega-menu-mobile') {
    function animateMenu(containerEl, direction) {
        containerEl.querySelectorAll('.primary-container').forEach((el) => {
            el.addEventListener(
                'animationend',
                () => el.classList.remove(direction),
                false
            );
            el.classList.add(direction);
        });
    }

    const animateDownMenu = (containerEl) =>
        animateMenu(containerEl, 'slide-in');

    const animateUpMenu = (containerEl) =>
        animateMenu(containerEl, 'slide-out');

    function megamenumobile(megaMenuEl) {
        // Primary toggled items are the first level navigation.  We are hiding the
        // primary navigation, and displaying the "relative" secondary navigation
        megaMenuEl.querySelectorAll('.primary-toggle').forEach((elem) =>
            elem.addEventListener('click', (event) => {
                event.preventDefault();
                event.stopPropagation();

                const currentText = event.currentTarget.innerText;
                if (event.currentTarget.classList.contains('menu-active')) {
                    return;
                }
                event.currentTarget.classList.add('menu-active');
                event.currentTarget
                    .querySelectorAll(':scope span.primary-title')
                    .forEach((el) => el.classList.add('active'));
                event.currentTarget
                    .querySelectorAll(':scope i.fa.fa-angle-right')
                    .forEach((el) => (el.style.visibility = 'hidden'));

                // Hide
                const primaryToggleMenuActive = megaMenuEl.querySelector(
                    '.primary-toggle.menu-active'
                );
                megaMenuEl
                    .querySelectorAll(
                        'ul.tertiary-menu, ul.utility-menu--mobile'
                    )
                    .forEach((el) => (el.style.display = 'none'));
                megaMenuEl.querySelectorAll('ul.primary-menu').forEach((el) => {
                    if (
                        el !==
                        primaryToggleMenuActive.closest('ul.primary-menu')
                    ) {
                        el.style.display = 'none';
                    }
                });
                // Show
                jQuery('.primary-back').show();
                primaryToggleMenuActive
                    .closest('li.nav-item')
                    .querySelectorAll(
                        '.field-menudescription, ul.secondary-menu'
                    )
                    .forEach((el) => (el.style.display = ''));
                megaMenuEl
                    .querySelectorAll('.primary-back')
                    .forEach((el) => (el.style.display = ''));

                // update link text
                megaMenuEl
                    .querySelectorAll('.back-text')
                    .forEach((el) => (el.textContent = currentText));

                animateDownMenu(megaMenuEl);
            })
        );

        // Secondary toggled items are the second level or navigation.  We are hiding the
        // secondary navigation, and displaying the "relative" third level of navigation
        megaMenuEl.querySelectorAll('.secondary-toggle').forEach((elem) =>
            elem.addEventListener('click', (event) => {
                event.preventDefault();
                event.stopPropagation();

                if (event.currentTarget.classList.contains('menu-active')) {
                    console.log('secondary toggle contains menu-active');
                    return;
                }
                event.currentTarget.classList.add('menu-active');
                // hide
                megaMenuEl
                    .querySelectorAll('.primary-back')
                    .forEach((el) => (el.style.display = 'none'));

                // hide
                const secondaryMenuActive = megaMenuEl.querySelector(
                    '.secondary-toggle.menu-active'
                );
                secondaryMenuActive
                    .closest('ul.secondary-menu')
                    .querySelectorAll('.secondary-nav-item')
                    .forEach((el) => {
                        if (el !== secondaryMenuActive.parentNode.parentNode) {
                            el.style.display = 'none';
                        } else {
                            el.classList.remove('cf-title-descr__secondary');
                            el.classList.add('cf-title-descr');
                        }
                    });

                // hide
                megaMenuEl
                    .querySelectorAll('.primary-menu .primary-link')
                    .forEach((el) => (el.style.display = 'none'));
                megaMenuEl
                    .querySelectorAll('.primary-menu .field-menudescription')
                    .forEach((el) => (el.style.display = 'none'));

                // show
                secondaryMenuActive.parentNode.parentNode
                    .querySelectorAll(
                        '.tertiary-menu, .tertiary-nav-item, .secondary-nav, .tertiary-menu .primary-link'
                    )
                    .forEach((el) => (el.style.display = ''));
                megaMenuEl
                    .querySelectorAll('.secondary-back')
                    .forEach((el) => (el.style.display = ''));

                // update classes
                secondaryMenuActive.parentNode
                    .querySelectorAll('.secondary-nav-item')
                    .forEach((el) =>
                        el.classList.remove('cf-title-descr__secondary')
                    );
                secondaryMenuActive.parentNode
                    .querySelectorAll('.secondary-nav-item')
                    .forEach((el) => el.classList.add('cf-title-descr'));

                // update classes
                megaMenuEl
                    .querySelectorAll(`:scope i.fa.fa-angle-right`)
                    .forEach((el) => (el.style.visibility = 'hidden'));
                event.currentTarget
                    .querySelectorAll(`:scope span.secondary-title`)
                    .forEach((el) => {
                        el.classList.add('active');
                    });

                animateDownMenu(megaMenuEl);
            })
        );

        // Here we are assuming secondary navigation is showing, so we are hiding
        // secondary navigation and displaying primary navigation once more
        megaMenuEl.querySelectorAll('.primary-back').forEach((elem) =>
            elem.addEventListener('click', (event) => {
                event.preventDefault();
                event.stopPropagation();

                // hide
                megaMenuEl
                    .querySelectorAll('.secondary-menu, .primary-back')
                    .forEach((el) => (el.style.display = 'none'));

                // show
                megaMenuEl
                    .querySelectorAll(
                        'ul.primary-menu, ul.utility-menu--mobile'
                    )
                    .forEach((el) => (el.style.display = ''));
                megaMenuEl
                    .querySelectorAll('.primary-menu .primary-link')
                    .forEach((el) => (el.style.display = ''));

                // hide again
                megaMenuEl
                    .querySelectorAll('.primary-menu .field-menudescription')
                    .forEach((el) => (el.style.display = 'none'));

                // update classes
                megaMenuEl
                    .querySelectorAll(`:scope ${'i.fa.fa-angle-right'}`)
                    .forEach((el) => (el.style.visibility = 'visible'));
                megaMenuEl
                    .querySelectorAll('.primary-toggle,.secondary-toggle')
                    .forEach((el) => el.classList.remove('menu-active'));
                megaMenuEl
                    .querySelectorAll('.primary-title,.secondary-title')
                    .forEach((el) => el.classList.remove('active'));

                animateUpMenu(megaMenuEl);
            })
        );

        // Here we are assuming third level navigation is showing, so we are hiding third
        // level navigation and displaying secondary navigation once more
        megaMenuEl.querySelectorAll('.secondary-back').forEach((elem) =>
            elem.addEventListener('click', (event) => {
                event.preventDefault();
                event.stopPropagation();

                // hide
                megaMenuEl
                    .querySelectorAll('.tertiary-menu, .secondary-back')
                    .forEach((el) => (el.style.display = 'none'));

                // show
                megaMenuEl
                    .querySelectorAll('.secondary-nav-item, .primary-back')
                    .forEach((el) => (el.style.display = ''));
                // secondary-menu siblings
                megaMenuEl
                    .querySelectorAll('.primary-menu .primary-link')
                    .forEach((el) => (el.style.display = ''));
                megaMenuEl
                    .querySelectorAll('.primary-menu .secondary-menu')
                    .forEach((el) => (el.style.display = ''));
                megaMenuEl
                    .querySelectorAll('.primary-menu .field-menudescription')
                    .forEach((el) => (el.style.display = ''));
                megaMenuEl
                    .querySelectorAll(
                        '.primary-link .dropdown-toggle.secondary-toggle'
                    )
                    .forEach((el) => el.classList.remove('menu-active'));

                // hide again
                megaMenuEl
                    .querySelectorAll(
                        '.cf-mega-menu-mobile .secondary-menu .field-menudescription'
                    )
                    .forEach((el) => (el.style.display = ''));

                // update classes
                megaMenuEl
                    .querySelectorAll('.secondary-nav-item')
                    .forEach((el) =>
                        el.classList.add('cf-title-descr__secondary')
                    );
                megaMenuEl
                    .querySelectorAll('.secondary-nav-item')
                    .forEach((el) => el.classList.remove('cf-title-descr'));
                megaMenuEl
                    .querySelectorAll(':scope i.fa.fa-angle-right')
                    .forEach((el) => (el.style.visibility = 'visible'));
                megaMenuEl
                    .querySelectorAll('span.secondary-title')
                    .forEach((el) => el.classList.remove('active'));

                animateUpMenu(megaMenuEl);
            })
        );

        megaMenuEl.querySelectorAll('.reset-main-screen').forEach((elem) => {
            elem.addEventListener('click', (event) => {
                event.preventDefault();
                document.body.classList.toggle(
                    'mobile-menu-open',
                    !elem.classList.contains('collapsed')
                );
                // hide
                megaMenuEl
                    .querySelectorAll(
                        '.secondary-menu, .primary-back, .secondary-back'
                    )
                    .forEach((el) => (el.style.display = 'none'));

                // show
                megaMenuEl
                    .querySelectorAll(
                        'ul.primary-menu, ul.utility-menu--mobile'
                    )
                    .forEach((el) => (el.style.display = ''));
                megaMenuEl
                    .querySelectorAll('.primary-menu .primary-link')
                    .forEach((el) => (el.style.display = ''));

                // hide again
                megaMenuEl
                    .querySelectorAll('.primary-menu .field-menudescription')
                    .forEach((el) => (el.style.display = 'none'));

                // hide and show secondary back elements
                megaMenuEl
                    .querySelectorAll('.tertiary-menu, .secondary-back')
                    .forEach((el) => (el.style.display = 'none'));
                megaMenuEl
                    .querySelectorAll('.secondary-menu .field-menudescription')
                    .forEach((el) => (el.style.display = 'none'));
                megaMenuEl
                    .querySelectorAll('.secondary-nav-item')
                    .forEach((el) => (el.style.display = ''));

                // update classes
                megaMenuEl
                    .querySelectorAll('.secondary-nav-item')
                    .forEach((el) =>
                        el.classList.add('cf-title-descr__secondary')
                    );
                megaMenuEl
                    .querySelectorAll('.secondary-nav-item')
                    .forEach((el) => el.classList.remove('cf-title-descr'));
                megaMenuEl
                    .querySelectorAll(':scope i.fa.fa-angle-right')
                    .forEach((el) => (el.style.visibility = 'visible'));
                megaMenuEl
                    .querySelectorAll('.primary-toggle,.secondary-toggle')
                    .forEach((el) => el.classList.remove('menu-active'));
                megaMenuEl
                    .querySelectorAll('.primary-title,.secondary-title')
                    .forEach((el) => el.classList.remove('active'));

                animateUpMenu(megaMenuEl);
            });
        });
    }

    document.querySelectorAll(selector).forEach((el) => megamenumobile(el));
}
