<script>
    import { onMount, afterUpdate } from 'svelte';
    import { debug } from '../lib/constants';
    import { DOM_SEARCH_RESULTS_LOADED } from '../lib/site-search-events';
    import analyticsManager from '../lib/site-analytics';

    // Remark, currently do not need id, this would be relevant if we have more
    // than one facet control and/or search results on the page
    export let id = 'unknown';
    export let facetHeading;
    export let facetReset;

    const searchResultsLoadedEvent = DOM_SEARCH_RESULTS_LOADED;
    const TAG_LIST_CHANGED_EVENT = 'tag-list-changed';

    let tooltips = [];
    let tagArray = [];
    let facetOptions = [];
    let tagListElement;
    let selectedFacetElement;
    let accordionElement;
    let expanded = true;

    const isExpanded = (width) => width > 786;

    function DebugLog(whatToLog) {
        if (debug) console.log('-- Debug from searchFacet.svelte --');
        if (debug) console.log(whatToLog);
    }

    async function init() {
        DebugLog('initializing component');
        DebugLog(
            `listening to event ${searchResultsLoadedEvent} from control with id ${id}`
        );

        expanded = isExpanded(document.documentElement.clientWidth);

        document.body.addEventListener(
            searchResultsLoadedEvent,
            handleSearchResultsLoadedEvent
        );
        document.body.addEventListener(
            TAG_LIST_CHANGED_EVENT,
            handleSelectedFacetsChangeEvent
        );

        function handleSearchResultsLoadedEvent(e) {
            DebugLog('handling dom event');
            DebugLog(e.detail);

            var selectedFacets = getSelectedFacet(e);

            let selectedTags = toTagList(selectedFacets);
            tagArray = selectedTags;
            selectedFacetElement.style.display =
                selectedTags.length <= 0 ? 'none' : 'block';

            // search results can be configured by content authors to have preset filters applied and we retreieve them here
            let presetFilters = e.detail.dataProperties.filters;

            // a keyword search term can match a topic
            let searchTermMatchingTopic =
                e.detail.resultData.searchTermMatchingTopic;

            // Check if the filtered list on the dynamic listing is checked
            let filteredlist = e.detail.dataProperties.filteredList;
            DebugLog('-----filteredlist-----');
            DebugLog(filteredlist);
            let presetFiltersfacets = e.detail.dataProperties.filtersfacets;
            if (filteredlist) {
                facetOptions = presetFiltersfacets
                    .map((f) =>
                        buildOptionsfilters(
                            f,
                            presetFiltersfacets,
                            searchTermMatchingTopic,
                            filteredlist
                        )
                    )
                    .filter((f) => f.options.length);
            } else {
                facetOptions = e.detail.resultData.facets
                    .map((f) =>
                        buildOptions(
                            f,
                            presetFilters,
                            searchTermMatchingTopic,
                            filteredlist
                        )
                    )
                    .filter((f) => f.options.length);
            }

            accordionElement.style.display =
                facetOptions.length <= 0 ? 'none' : 'block';

            DebugLog('-----facet options-----');
            DebugLog(facetOptions);

            function getSelectedFacet(e) {
                if (e.detail.queryObj && e.detail.queryObj.fp) {
                    return e.detail.queryObj.fp;
                }

                return e.detail.resultData.pagingInfo.selectedFacets;
            }

            function buildOptions(
                facet,
                presetFilters,
                searchTermMatchingTopic,
                filteredlist
            ) {
                let options = [];

                if (facet.facetValues) {
                    let filteredFacetValues = facet.facetValues;

                    // filter out the topic facet value if it is a matching search term
                    if (
                        facet.name.toLowerCase() === 'taxonomytopics' &&
                        searchTermMatchingTopic
                    ) {
                        filteredFacetValues = filteredFacetValues.filter(
                            (f) =>
                                f.value.toLowerCase() !==
                                searchTermMatchingTopic.toLowerCase()
                        );
                    }

                    // check if there is a preset filter 'category' matching the facet 'category'
                    // (e.g. we have a preset filter on publications and also are faceting on publications)
                    let presetFilterMatch = findPresetFilterMatch(
                        facet.name,
                        presetFilters
                    );
                    if (!filteredlist) {
                        if (presetFilterMatch) {
                            let presetFilterValues =
                                presetFilters[presetFilterMatch];
                            // this will filter out any content author preset filter values from the facet values
                            // this is becauase we do not want to display these facet values to the end user
                            filteredFacetValues = filteredFacetValues.filter(
                                isNotAPresetFilterValue(presetFilterValues)
                            );
                        }
                    }

                    options = filteredFacetValues.map(createOption);
                }

                return {
                    title: facet.displayName,
                    name: facet.name,
                    options,
                    tooltip: facet.facetTooltip,
                };

                function findPresetFilterMatch(facetName, presetFilters) {
                    // this is kinda janky. our filter properties don't come in with the same casing as our facets. For example:
                    // the preset topics filter comes in with a capital T, as in Topics. but our facet is a lowercase t, as in topics.
                    return Object.keys(presetFilters).find(
                        (key) => key.toLowerCase() === facetName.toLowerCase()
                    );
                }

                function isNotAPresetFilterValue(presetFilterValues) {
                    return function (facetValue) {
                        return !presetFilterValues.includes(facetValue.value);
                    };
                }
                function isAPresetFilterValue(presetFilterValues) {
                    return function (facetValue) {
                        return presetFilterValues.includes(facetValue.value);
                    };
                }
            }
            function buildOptionsfilters(
                facet,
                presetFiltersfacets,
                searchTermMatchingTopic,
                filteredlist
            ) {
                let options = [];
                let filteredFacetValues = facet.OrValues;
                DebugLog('-----filteredFacetValues-----');
                DebugLog(filteredFacetValues);
                options = filteredFacetValues.map(createEmptyOption);

                return {
                    title: facet.displayName,
                    name: facet.Name,
                    options,
                    tooltip: facet.facetTooltip,
                };
            }
        }
    }

    onMount(init);

    function handleSelectedFacetsChangeEvent(e) {
        function unselectIfMatch(valueToMatch) {
            return function scopedFunc(option) {
                if (option.value == valueToMatch) {
                    option.selected = false;
                }
            };
        }

        const changeOnMatch = unselectIfMatch(e.target.value);
        facetOptions.forEach((facet) => facet.options.forEach(changeOnMatch));
        facetOptions = facetOptions;

        analyticsManager.PushAnalyticsEvent('custom_event_facet_quick_remove', {
            targetElement: e.currentTarget,
            tag: e.target.value,
        });

        let selectedFacets = getSelectedFacets();
        let selectedTags = toTagList(selectedFacets);
        selectedFacetElement.style.display =
            selectedTags.length <= 0 ? 'none' : 'block';

        sendFacetChangeEvent(this, selectedFacets);
    }

    function reportWindowSize(e) {
        expanded = isExpanded(document.documentElement.clientWidth);
    }

    function createOption(item) {
        let itmOption = {
            value: item.value,
            count: item.count,
            selected: tagArray.includes(item.value),
        };
        return itmOption;
    }

    function createEmptyOption(item) {
        let itmOption = {
            value: item,
            selected: tagArray.includes(item),
        };
        return itmOption;
    }

    function facetsChanged(e) {
        const eventName = e.target.checked
            ? 'custom_event_facet_added'
            : 'custom_event_facet_removed';

        analyticsManager.PushAnalyticsEvent(eventName, {
            targetElement: e.currentTarget,
            tag: e.currentTarget.value,
        });

        const { selectedFacets, selectedTags } = findSelectedState();
        tagArray = selectedTags;
        sendFacetChangeEvent(this, selectedFacets); // "this" is the item in context, which should be input element that fired the event.
    }

    function findSelectedState() {
        let selectedFacets = getSelectedFacets();
        let selectedTags = toTagList(selectedFacets);
        selectedFacetElement.style.display =
            selectedTags.length <= 0 ? 'none' : 'block';

        return { selectedFacets, selectedTags };
    }

    function sendFacetChangeEvent(obj, selectedFacets) {
        let event = new CustomEvent('facets-changed', {
            detail: selectedFacets,
            bubbles: true,
            cancelable: true,
            composed: true,
        });
        obj.dispatchEvent(event);
    }

    function toTagList(selectedFacets) {
        if (!selectedFacets.hasOwnProperty('facets')) {
            return [];
        }
        let tags = selectedFacets.facets.flatMap((fct) =>
            fct.facetValues.map((fv) => fv.value)
        );
        return tags;
    }
    function getSelectedFacets() {
        let selectedFacets = facetOptions.map(appendIfSelected);
        selectedFacets = selectedFacets.filter(
            (fct) => fct.facetValues.length > 0
        );

        return { facets: selectedFacets };

        function appendIfSelected(facet, _, __) {
            let findSelectedOptions = findSelected(facet.options);
            let newFacet = {
                displayName: facet.title,
                name: facet.name,
                facetValues: findSelectedOptions.map((opt) => ({
                    value: opt.value,
                    facetTooltip: facet.tooltip,
                })),
            };

            return newFacet;
        }
    }

    function findSelected(itmArray) {
        return itmArray.filter((itm) => itm.selected == true);
    }

    function reset(e) {
        analyticsManager.PushAnalyticsEvent('custom_event_reset_button', {
            targetElement: e.currentTarget,
        });
        facetOptions.forEach((facet) =>
            facet.options.forEach((opt) => (opt.selected = false))
        );
        facetOptions = facetOptions; // Remark: svelte keys off the "=" operator to repaint the ui when a variable changes.

        const { selectedFacets, selectedTags } = findSelectedState();
        tagArray = selectedTags;
        sendFacetChangeEvent(this, selectedFacets); // this should be reset button
    }

    afterUpdate(() => {
        tooltips.forEach((instance) => instance.dispose());
        tooltips = Array.from(
            accordionElement.querySelectorAll('[data-bs-toggle="tooltip"]')
        ).map((el) => new bootstrap.Tooltip(el));
    });
</script>

<svelte:window on:resize={reportWindowSize} />

<div class="search-facets-container">
    <details bind:this={accordionElement} open={expanded}>
        <summary>
            <h5>{facetHeading}</h5>
        </summary>
        <div>
            <div>
                <form class="search-refinement">
                    <div class="search-refinement__facets">
                        {#each facetOptions as aFacet}
                            <div class="search-refinement__facet">
                                <fieldset>
                                    <legend>
                                        {aFacet.title}
                                        {#if aFacet?.tooltip}
                                            <button
                                                type="button"
                                                class="btn btn-tooltip"
                                                data-bs-toggle="tooltip"
                                                data-bs-title={aFacet?.tooltip}
                                            >
                                                <i
                                                    class="fas fa-circle-question"
                                                ></i>
                                            </button>
                                        {/if}
                                    </legend>
                                    <div class="facet-field-container">
                                        {#each aFacet.options as item}
                                            <div class="facet-field">
                                                <input
                                                    type="checkbox"
                                                    id="{aFacet.name}_{item.value}"
                                                    value={item.value}
                                                    bind:checked={item.selected}
                                                    on:change={facetsChanged}
                                                />
                                                <label
                                                    for="{aFacet.name}_{item.value}"
                                                >
                                                    {item.value}{#if item?.count}
                                                        ({item.count})
                                                    {/if}
                                                </label>
                                            </div>
                                        {/each}
                                    </div>
                                </fieldset>
                            </div>
                        {/each}
                    </div>

                    <menu>
                        <div
                            class="selected-slot"
                            bind:this={selectedFacetElement}
                        >
                            <ul class="list-unstyled taglist-container" bind:this={tagListElement}>
                                {#each tagArray as tag}
                                    <li>
                                        <button
                                            class="button"
                                            on:click={handleSelectedFacetsChangeEvent}
                                            value={tag}
                                            type="button"
                                            title={tag}
                                        >
                                            <span class="text-truncate">{tag}</span>X
                                        </button>
                                    </li>
                                {/each}
                            </ul>
                        </div>
                        <div class="selected-reset">
                            <button
                                type="button"
                                class="button button--secondary"
                                on:click={reset}
                            >
                                {facetReset}
                            </button>
                        </div>
                    </menu>
                </form>
            </div>
        </div>
    </details>
</div>

<style lang="scss">
    @import './searchFacets.scss';
</style>
