import { css } from 'lit';

export const popoverStyles = css`
    :host([data-theme='dark']) .popover {
        --cf-popover-bg: var(--cf-color-black);
        --cf-popover-color: var(--cf-color-white);
    }
    
    .popover {
        --cf-popover-bg: var(--cf-color-white);
        --cf-popover-border-color-left: var(--cf-color-tertiary-6);
        --cf-popover-border-color: var(--cf-color-secondary-6);

        background: var(--cf-popover-bg);
        border-top: 1px solid var(--cf-popover-border-color);
        border-right: 1px solid var(--cf-popover-border-color);
        border-bottom: 1px solid var(--cf-popover-border-color);
        border-left: 10px solid var(--cf-popover-border-color-left);
        padding: var(--cf-space-xxs);
        box-shadow: var(--cf-shadow-md);
        border-radius: 0;
        margin: var(--cf-space-xxs);
    }
`;
