import { css } from 'lit';

export const heading1Styles = css`
    .h1 {
        font-family: helveticanow-light, Helvetica, Arial, sans-serif;
        font-size: 3rem;
        color: #231f20;
        letter-spacing: normal;
        margin-top: 0;
        margin-bottom: 0.5rem;
        line-height: 1.2;
    }
`;

export const heading2Styles = css`
    .h2 {
        font-family: helveticanow-bold-condensed, Helvetica, Arial, sans-serif;
        font-size: 1.625rem;
        color: #414e58;
        letter-spacing: 1px;
        margin-top: 0;
        margin-bottom: 0.5rem;
        line-height: 1.2;
    }
`;

export const heading3Styles = css`
    .h3 {
        font-family: helveticanow-bold-condensed, Helvetica, Arial, sans-serif;
        font-size: 1.375rem;
        color: #e65722;
        letter-spacing: 1px;
        margin-top: 0;
        margin-bottom: 0.5rem;
        line-height: 1.2;
    }
`;

export const heading4Styles = css`
    .h4 {
        font-family: helveticanow-medium-condensed, Helvetica, Arial, sans-serif;
        font-size: 1.125rem;
        color: #0d3051;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: 0.5rem;
        line-height: 1.2;
    }
`;

export const heading5Styles = css`
    .h5 {
        font-family: helveticanow-bold-condensed, Helvetica, Arial, sans-serif;
        font-size: 1.25rem;
        color: #414e58;
        letter-spacing: 1px;
        margin-top: 0;
        margin-bottom: 0.5rem;
        line-height: 1.2;
    }
`;

export const heading6Styles = css`
    .h6 {
        --heading-color: #414e58;

        font-family: helveticanow-heavy, Helvetica, Arial, sans-serif;
        font-size: 1rem;
        color: var(--heading-color);
        letter-spacing: normal;
        margin-top: 0;
        margin-bottom: 0.5rem;
        line-height: 1.2;
    }
`;

export const headingStyles = css`
    ${heading1Styles}
    ${heading2Styles}
    ${heading3Styles}
    ${heading4Styles}
    ${heading5Styles}
    ${heading6Styles}
`;
